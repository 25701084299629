import React, { useEffect, useState } from 'react';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';
import {
  Tabs,
  Divider,
  Input,
  Checkbox,
  Radio,
  Select,
  DatePicker,
  Button,
  Form,
  Row,
  Col,
  Space,
  TreeSelect,
  Popconfirm,
} from 'antd';
import dayjs from 'dayjs';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import DNDPrivGallery from '../base/DNDPrivGallery';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/sr_RS';
import { GalleryModal } from './';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const formInit = {
  _id: null,
  months: 24,
  entryDate: '',
};

const EopForm = ({ isNew, data, realEstates, language, onSubmit, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [anon, setAnon] = useState();
  const [selectedRE, setSelectedRE] = useState(!isNew && data && data.realEstate && data.realEstate._id);
  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };

  if (data) {
    if (data.entryDate) form.setFieldsValue({ entryDate: moment.utc(data.entryDate) });
    if (data.expireDate) form.setFieldsValue({ expireDate: moment.utc(data.expireDate) });
    if (data.contractDate) form.setFieldsValue({ contractDate: moment.utc(data.contractDate) });
    if (data.birthDate) form.setFieldsValue({ birthDate: moment.utc(data.birthDate) });
  }

  useEffect(() => {
    if (data) {
      if (data.featuredAdUntil) form.setFieldsValue({ featuredAdUntil: dayjs(data.featuredAdUntil) });

      if (data.realEstate) {
        form.setFieldsValue({
          realEstate: selectedRE,
        });

        form.setFields([
          {
            name: 'realEstate',
            placeholder: `${data.realEstate.city}, ${data.realEstate.address} ${data.realEstate.location.locationName}`,
          },
        ]);
      }
    }
  }, [data, form, SERVER_URL, initialValues]);

  const onFinish = async (values, isNew) => {
    console.log(values);
    if (values.contractDate === null) {
      values.contractDate = '';
    }
    const selectedRealEstate = realEstates.items.find((realEstate) => realEstate._id === selectedRE);

    if (selectedRealEstate.price) {
      values.price = selectedRealEstate.price;
    }
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  const handleRealEstateChange = (value) => {
    const selectedRealEstate = realEstates.items.find((realEstate) => realEstate._id === value);
    setSelectedRE(value);
    if (selectedRealEstate) {
      if (selectedRealEstate?.coOwners) {
        const party = [
          selectedRealEstate?.client?.clientName,
          ...selectedRealEstate?.coOwners.map((coowner) => coowner.coowner.clientName),
        ];
        form.setFieldsValue({ client: party.join(', ') });
        form.setFieldsValue({
          coOwners: selectedRealEstate?.coOwners.map((coowner) => {
            return {
              name: coowner.coowner.clientName,
            };
          }),
        });
        form.setFieldsValue({
          addressClients: [
            selectedRealEstate?.client?.address,
            ...selectedRealEstate?.coOwners.map((coowner) => coowner.coowner.address),
          ].join(', '),
        });
        form.setFieldsValue({ cut: `1/${party.length}` });
      } else {
        form.setFieldsValue({ client: selectedRealEstate?.client?.clientName });
        form.setFieldsValue({ cut: `1/1` });
        form.setFieldsValue({
          addressClients: selectedRealEstate?.client?.address,
        });
        form.setFieldsValue({
          coOwners: [],
        });
      }

      if (selectedRealEstate?.client?.jmbg) {
        form.setFieldsValue({
          jmbgPib: selectedRealEstate?.client?.jmbg,
        });
      } else {
        form.setFieldsValue({
          jmbgPib: '',
        });
      }

      if (selectedRealEstate?.client?.brLk) {
        form.setFieldsValue({ blkMb: selectedRealEstate?.client?.brLk });
      } else {
        form.setFieldsValue({ blkMb: '' });
      }
      form.setFieldsValue({ contractNum: selectedRealEstate.realEstateId });
      form.setFieldsValue({ contractDate: moment.utc(selectedRealEstate.contractDate) });
      form.setFieldsValue({ expireDate: moment.utc(selectedRealEstate.contractDate).add(2, 'years') });
      form.setFieldsValue({ entryDate: moment.utc(selectedRealEstate?.createdByUserDate) });
    }
  };

  const onRadioChange = (e) => {
    setAnon(e.target.value);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='eop-form'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col span={8}>
              <Form.Item label='Adresa nekretnine' name='realEstate'>
                <Select
                  virtual={false}
                  className='eop-form-blue-text'
                  showSearch
                  allowClear='true'
                  placeholder='Pretražite nekretninu...'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children
                      .normalize('NFD') // Normalize diacritic characters
                      .toLowerCase()
                      .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                  }
                  onChange={handleRealEstateChange}
                >
                  {realEstates?.items?.map((realEstate, key) => (
                    <Option key={key} value={realEstate._id}>{`${realEstate.city}, ${realEstate.address} ${
                      realEstate?.location !== undefined && realEstate.location.locationName
                    }`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row type='flex' gutter={16}>
            <Col>
              <Form.Item label='Ime (naziv) stranke' name='client'>
                <Input className='eop-form-blue-text eop-form__coowner-party' />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label='Udeo' name='cut'>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* <Col span={6}>
            <Form.List name='coOwners'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} align='baseline'>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        rules={[
                          {
                            required: true,
                            message: 'Unesite ime i prezime suvlasnika.',
                          },
                        ]}
                        className='eop-form__coowner-name'
                      >
                        <Input placeholder='Ime i prezime suvlasnika...' className='eop-form-blue-text' />
                      </Form.Item>

                      
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Col span={17}>
                    <Form.Item>
                      <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                        Dodaj suvlasnika
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
          </Col> */}
          <Row type='flex' gutter={16}>
            <Col span={8}>
              <Form.Item label='Adresa stranke' name='addressClients'>
                <Input className='eop-form-blue-text' />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={3}>
              <Form.Item
                label='Vrsta lica'
                name='typeClient'
                rules={[
                  {
                    required: true,
                    message: 'Ovo polje je obavezno.',
                  },
                ]}
              >
                <Select virtual={false} allowClear='true'>
                  {[
                    'Fizičko lice',
                    'Preduzetnik',
                    'D.O.O',
                    'Komanditno društvo',
                    'Ortačko društvo',
                    'Akcionarsko društvo',
                    'Poslovno udruženje',
                    'Zadruga',
                    'Poljoprivredno gazdinstvo',
                    'Lice gr. prava',
                    'Udruženje',
                    'Zadužbina',
                    'Fondacija',
                    'Udruženje-nevladina organizacija',
                    'Sportsko udruženje',
                    'Verska zajednica i crkva',
                    'Politička stranka',
                    'Sindikat',
                    'Jedinica lokalne samouprave',
                    'Ustanova',
                    'Javno preduzeće',
                    'Strano predstavništvo',
                    'Ogranak stranog privrednog društva',
                    'Trast',
                    'Ostalo',
                  ].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label='JMBG (PIB)' name='jmbgPib'>
                <Input type='number' className='eop-form-blue-text' />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Broj LK (MB)' name='blkMb'>
                <Input type='number' className='eop-form-blue-text' />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={3}>
              <Form.Item label='Datum rodjenja' name='birthDate'>
                <DatePicker locale={locale} format='DD-MM-YYYY' />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={3}>
              <Form.Item label='Mesto rođenja' name='birthPlace'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={8}>
              <Form.Item label='Ostali podaci o vlasniku' name='otherClientData'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row type='flex' gutter={16}>
            <Col span={3}>
              <Form.Item label='Delatnost stranke' name='typeClientOcupation'>
                <Select virtual={false} allowClear='true'>
                  {['Penzioner', 'Zaposlen'].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={8}
              style={{
                alignSelf: 'end',
              }}
            >
              <Form.Item name='clientOcupation'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={4}>
              <Form.Item
                label='Rezidentnost'
                name='residency'
                rules={[
                  {
                    required: true,
                    message: 'Ovo polje je obavezno.',
                  },
                ]}
              >
                <Select virtual={false} allowClear='true'>
                  {[
                    'REZ - domaći',
                    'REZ - granična područja',
                    'NER - stranac iz okruženja',
                    'NER - stranac bela lista',
                    'NER - stranac srednji georizik',
                    'NER - stranac crna lista',
                  ].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item
                label='Država prebivališta/sedišta'
                name='domicile'
                rules={[
                  {
                    required: true,
                    message: 'Ovo polje je obavezno.',
                  },
                ]}
              >
                <Select virtual={false} allowClear='true'>
                  {[
                    'Srbija',
                    'Crna Gora',
                    'BiH',
                    'Hrvatska',
                    'Slovenija',
                    'Severna Makedonija',
                    'Grčka',
                    'Švajcarska',
                    'Francuska',
                    'Španija',
                    'Velika Britanija',
                    'Italija',
                    'Turska',
                    'Mađarska',
                    'Rusija',
                    'Holandija',
                    'Bugarska',
                    'USA',
                    'Kanada',
                    'Nemačka',
                    'Malta',
                    'Austrija',
                    'Australija',
                    'Izrael',
                    'Liban',
                    'Sirija',
                    'Kina',
                    'Ukrajina',
                    'Iran',
                    'Švedska',
                    'Kipar',
                    'Egipat',
                    'Jordan',
                    'Rumunija',
                    'Irak',
                    'Irska',
                    'Novi Zeland',
                    'Slovačka',
                    'Singapur',
                    'Japan',
                    'Kuba',
                    'Belgija',
                    'Južnoafrička Republika',
                    'Finska',
                    'Norveška',
                    'Luksemburg',
                    'Češka',
                    'Južna Koreja',
                    'Severna Koreja',
                    'Belorusija',
                    'Kongo',
                    'Polska',
                    'Venecuela',
                    'UAE',
                    'Pakistan',
                    'Filipini',
                    'Azerbejdžan',
                    'Litvanija',
                  ].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label='Opština prebivališta/sedišta' name='township'>
                <Select virtual={false} allowClear='true'>
                  {[
                    'Ada (SEVERNO-BANATSKI OKRUG)',
                    'Aleksandrovac (RASINSKI OKRUG)',
                    'Aleksinac (NIŠAVSKI OKRUG)',
                    'Alibunar (JUŽNO-BANATSKI OKRUG)',
                    'Apatin (ZAPADNO-BAČKI OKRUG)',
                    'Aranđelovac (ŠUMADIJSKI OKRUG)',
                    'Arilje (ZLATIBORSKI OKRUG)',
                    'Babušnica (PIROTSKI OKRUG)',
                    'Bač (JUŽNO-BAČKI OKRUG)',
                    'Bačka Palanka (JUŽNO-BAČKI OKRUG)',
                    'Bačka Topola (SEVERNO-BAČKI OKRUG)',
                    'Bački Petrovac (JUŽNO-BAČKI OKRUG)',
                    'Bajina Bašta (ZLATIBORSKI OKRUG)',
                    'Beograd (BEOGRAD)',
                    'Batočina (ŠUMADIJSKI OKRUG)',
                    'Bečej (JUŽNO-BAČKI OKRUG)',
                    'Bela Crkva (JUŽNO-BANATSKI OKRUG)',
                    'Bela Palanka (PIROTSKI OKRUG)',
                    'Beočin (JUŽNO-BAČKI OKRUG)',
                    'Blace (TOPLIČKI OKRUG)',
                    'Bogatić (MAČVANSKI OKRUG)',
                    'Bojnik (JABLANIČKI OKRUG)',
                    'Boljevac (ZAJEČARSKI OKRUG)',
                    'Bor (BORSKI OKRUG)',
                    'Bosilegrad (PČINJSKI OKRUG)',
                    'Brus (RASINSKI OKRUG)',
                    'Bujanovac (PČINJSKI OKRUG)',
                    'Čačak (MORAVIČKI OKRUG)',
                    'Čajetina (ZLATIBORSKI OKRUG)',
                    'Ćićevac (RASINSKI OKRUG)',
                    'Čoka (SEVERNO-BANATSKI OKRUG)',
                    'Crna Trava (JABLANIČKI OKRUG)',
                    'Crveni Krst (NIŠ)',
                    'Ćuprija (POMORAVSKI OKRUG)',
                    'Despotovac (POMORAVSKI OKRUG)',
                    'Dimitrovgrad (PIROTSKI OKRUG)',
                    'Doljevac (NIŠAVSKI OKRUG)',
                    'Dragaš (KOSOVSKA MITROVICA)',
                    'Gadžin Han (NIŠAVSKI OKRUG)',
                    'Gnjilane (GNJILANE)',
                    'Golubac (BRANIČEVSKI OKRUG)',
                    'Gornji Milanovac (MORAVIČKI OKRUG)',
                    'Inđija (SREMSKI OKRUG)',
                    'Irig (SREMSKI OKRUG)',
                    'Istok (PEĆ)',
                    'Ivanjica (MORAVIČKI OKRUG)',
                    'Jagodina (POMORAVSKI OKRUG)',
                    'Kačanik (UROŠEVAC)',
                    'Kanjiža (SEVERNO-BANATSKI OKRUG)',
                    'KG-Pivara (KRAGUJEVAC)',
                    'KG-Stanovo (KRAGUJEVAC)',
                    'KG-Stari Grad (KRAGUJEVAC)',
                    'KG-Stragari (KRAGUJEVAC)',
                    'Kikinda (SEVERNO-BANATSKI OKRUG)',
                    'Kladovo (BORSKI OKRUG)',
                    'Klina (PEĆ)',
                    'Knić (ŠUMADIJSKI OKRUG)',
                    'Knjaževac (ZAJEČARSKI OKRUG)',
                    'Koceljeva (MAČVANSKI OKRUG)',
                    'Kopaonik (RAŠKI OKRUG)',
                    'Kosjerić (ZLATIBORSKI OKRUG)',
                    'Kosovo Polje (PRIŠTINA)',
                    'Kosovska Kamenica (PRIŠTINA)',
                    'Kosovska Mitrovica (KOSOVSKA MITROVICA)',
                    'Kostolac (BRANIČEVSKI OKRUG)',
                    'Kovačica (JUŽNO-BANATSKI OKRUG)',
                    'Kovin (JUŽNO-BANATSKI OKRUG)',
                    'Kragujevac (ŠUMADIJSKI OKRUG)',
                    'Kraljevo (RAŠKI OKRUG)',
                    'Krupanj (MAČVANSKI OKRUG)',
                    'Kruševac (RASINSKI OKRUG)',
                    'Kučevo (BRANIČEVSKI OKRUG)',
                    'Kula (ZAPADNO-BAČKI OKRUG)',
                    'Kuršumlija (TOPLIČKI OKRUG)',
                    'Lajkovac (KOLUBARSKI OKRUG)',
                    'Lapovo (ŠUMADIJSKI OKRUG)',
                    'Lebane (JABLANIČKI OKRUG)',
                    'Leposavić (KOSOVSKA MITROVICA)',
                    'Leskovac (JABLANIČKI OKRUG)',
                    'Lipljan (PRIŠTINA)',
                    'Ljig (KOLUBARSKI OKRUG)',
                    'Ljubovija (MAČVANSKI OKRUG)',
                    'Loznica (MAČVANSKI OKRUG)',
                    'Lučani (MORAVIČKI OKRUG)',
                    'Majdanpek (BORSKI OKRUG)',
                    'Mali Iđoš (SEVERNO-BAČKI OKRUG)',
                    'Mali Zvornik (MAČVANSKI OKRUG)',
                    'Malo Crniće (BRANIČEVSKI OKRUG)',
                    'Medijana (NIŠ)',
                    'Medveđa (JABLANIČKI OKRUG)',
                    'Merošina (NIŠAVSKI OKRUG)',
                    'Mionica (KOLUBARSKI OKRUG)',
                    'Negotin (BORSKI OKRUG)',
                    'Niška Banja (NIŠ)',
                    'Nova Crnja (SREDNJE-BANATSKI OKRUG)',
                    'Nova Varoš (ZLATIBORSKI OKRUG)',
                    'Novi Bečej (SREDNJE-BANATSKI OKRUG)',
                    'Novi Kneževac (SEVERNO-BANATSKI OKRUG)',
                    'Novi Pazar (RAŠKI OKRUG)',
                    'Novi Sad (NOVI SAD)',
                    'Novo Brdo (PRIŠTINA)',
                    'Odžaci (ZAPADNO-BAČKI OKRUG)',
                    'Opovo (JUŽNO-BANATSKI OKRUG)',
                    'Orahovac (ĐAKOVICA)',
                    'Osečina (KOLUBARSKI OKRUG)',
                    'Lapovo (ŠUMADIJSKI OKRUG)',
                    'Lučani (MORAVIČKI OKRUG)',
                    'Majdanpek (BORSKI OKRUG)',
                    'Mali Iđoš (SEVERNO-BAČKI OKRUG)',
                    'Mali Zvornik (MAČVANSKI OKRUG)',
                    'Malo Crniće (BRANIČEVSKI OKRUG)',
                    'Medijana (NIŠ)',
                    'Medveđa (JABLANIČKI OKRUG)',
                    'Merošina (NIŠAVSKI OKRUG)',
                    'Mionica (KOLUBARSKI OKRUG)',
                    'Negotin (BORSKI OKRUG)',
                    'Niška Banja (NIŠ)',
                    'Nova Crnja (SREDNJE-BANATSKI OKRUG)',
                    'Nova Varoš (ZLATIBORSKI OKRUG)',
                    'Novi Bečej (SREDNJE-BANATSKI OKRUG)',
                    'Novi Kneževac (SEVERNO-BANATSKI OKRUG)',
                    'Novi Pazar (RAŠKI OKRUG)',
                    'Novi Sad (NOVI SAD)',
                    'Novo Brdo (PRIŠTINA)',
                    'Odžaci (ZAPADNO-BAČKI OKRUG)',
                    'Opovo (JUŽNO-BANATSKI OKRUG)',
                    'Orahovac (ĐAKOVICA)',
                    'Osečina (KOLUBARSKI OKRUG)',
                    'Petrovac na Mlavi (BRANIČEVSKI OKRUG)',
                    'Petrovaradin (NOVI SAD)',
                    'Pirot (PIROTSKI OKRUG)',
                    'Plandište (JUŽNO-BANATSKI OKRUG)',
                    'Podujevo (PRIŠTINA)',
                    'Požarevac (BRANIČEVSKI OKRUG)',
                    'Požega (ZLATIBORSKI OKRUG)',
                    'Preševo (PČINJSKI OKRUG)',
                    'Priboj (ZLATIBORSKI OKRUG)',
                    'Prijepolje (ZLATIBORSKI OKRUG)',
                    'Priština (PRIŠTINA)',
                    'Prizren (PRIZREN)',
                    'Prokuplje (TOPLIČKI OKRUG)',
                    'Rača (ŠUMADIJSKI OKRUG)',
                    'Raška (RAŠKI OKRUG)',
                    'Ražanj (RASINSKI OKRUG)',
                    'Rekovac (POMORAVSKI OKRUG)',
                    'Ruma (SREMSKI OKRUG)',
                    'Šabac (MAČVANSKI OKRUG)',
                    'Sečanj (SREDNJE-BANATSKI OKRUG)',
                    'Senta (SEVERNO-BANATSKI OKRUG)',
                    'Šid (SREMSKI OKRUG)',
                    'Sjenica (ZLATIBORSKI OKRUG)',
                    'Smederevo (PODUNAVSKI OKRUG)',
                    'Smederevska Palanka (PODUNAVSKI OKRUG)',
                    'Sokobanja (ZAJEČARSKI OKRUG)',
                    'Sombor (ZAPADNO-BAČKI OKRUG)',
                    'Srbica (KOSOVSKA MITROVICA)',
                    'Srbobran (JUŽNO-BAČKI OKRUG)',
                    'Sremska Mitrovica (SREMSKI OKRUG)',
                    'Sremski Karlovci (JUŽNO-BAČKI OKRUG)',
                    'Stara Pazova (SREMSKI OKRUG)',
                    'Štimlje (UROŠEVAC)',
                    'Štrpce (UROŠEVAC)',
                    'Subotica (SEVERNO-BAČKI OKRUG)',
                    'Surdulica (PČINJSKI OKRUG)',
                    'Suva Reka (PRIZREN)',
                    'Svilajnac (POMORAVSKI OKRUG)',
                    'Svrljig (NIŠAVSKI OKRUG)',
                    'Tara (ZLATIBORSKI OKRUG)',
                    'Temerin (JUŽNO-BAČKI OKRUG)',
                    'Titel (JUŽNO-BAČKI OKRUG)',
                    'Topola (ŠUMADIJSKI OKRUG)',
                    'Trgovište (PČINJSKI OKRUG)',
                    'Trstenik (RASINSKI OKRUG)',
                    'Tutin (RAŠKI OKRUG)',
                    'Ub (KOLUBARSKI OKRUG)',
                    'Uroševac (UROŠEVAC)',
                    'Užice (ZLATIBORSKI OKRUG)',
                    'Valjevo (KOLUBARSKI OKRUG)',
                    'Varvarin (RASINSKI OKRUG)',
                    'Velika Plana (PODUNAVSKI OKRUG)',
                    'Veliko Gradište (BRANIČEVSKI OKRUG)',
                    'Vitina (GNJILANE)',
                    'Vladičin Han (PČINJSKI OKRUG)',
                    'Vladimirci (MAČVANSKI OKRUG)',
                    'Vlasotince (JABLANIČKI OKRUG)',
                    'Vranje (PČINJSKI OKRUG)',
                    'Vrbas (JUŽNO-BAČKI OKRUG)',
                    'Vrnjačka Banja (RAŠKI OKRUG)',
                    'Vršac (JUŽNO-BANATSKI OKRUG)',
                    'Vučitrn (KOSOVSKA MITROVICA)',
                    'Žabalj (JUŽNO-BAČKI OKRUG)',
                    'Žabari (BRANIČEVSKI OKRUG)',
                    'Žagubica (BRANIČEVSKI OKRUG)',
                    'Zaječar (ZAJEČARSKI OKRUG)',
                    'Žitište (SREDNJE-BANATSKI OKRUG)',
                    'Žitorađa (TOPLIČKI OKRUG)',
                    'Zlatibor (ZLATIBORSKI OKRUG)',
                    'Zrenjanin (SREDNJE-BANATSKI OKRUG)',
                    'Zubin Potok (KOSOVSKA MITROVICA)',
                    'Zvečan (KOSOVSKA MITROVICA)',
                    'Đakovica (ĐAKOVICA)',
                  ].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={4}>
              <Form.Item label='Rizična stranka' name='riskyClient'>
                <Select virtual={false} allowClear='true'>
                  {[
                    'NE',
                    'investitor',
                    'Funkcioner domaći',
                    'Strani funkcioner',
                    'OFF-shore',
                    'Stranka ili transakcija iz države sa "crne liste"',
                    'Stranka nije fizički prisutna prilikom identifikacije',
                    'Visokorizična po NPR',
                    'ostalo',
                  ].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Dokumentacija' name='documentation'>
                <Select virtual={false} allowClear='true'>
                  {['potpuna', 'nepotpuna (izjava funkc.?)', 'nepotpuna (očitanje LK?)'].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label='Kategorija rizika'
                name='riskType'
                rules={[
                  {
                    required: true,
                    message: 'Ovo polje je obavezno.',
                  },
                ]}
              >
                <Select virtual={false} allowClear='true'>
                  {['Nisko rizičan', 'Srednje rizičan', 'Visoko rizičan'].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={4}>
              <Form.Item
                label='Uspostavljen poslovni odnos'
                name='businessShip'
                rules={[
                  {
                    required: true,
                    message: 'Ovo polje je obavezno.',
                  },
                ]}
              >
                <Select virtual={false} allowClear='true'>
                  {[
                    'LIČNO',
                    'VIDEO Identifikacijom',
                    'Preko PUNOMOĆNIKA',
                    'Preko ADVOKATA',
                    'Preko drugog POSREDNIKA',
                    'drugo',
                  ].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row type='flex' gutter={16}>
            <Col span={3}>
              <Form.Item label='Broj ugovora' name='contractNum'>
                <Input className='eop-form-blue-text' />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col>
              <Form.Item label='Datum ugovora' name='contractDate'>
                <DatePicker locale={locale} format='DD-MM-YYYY' className='eop-form-blue-text' />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='Traje (meseci)' name='months' className='eop-form__months'>
                <Input type='number' disabled className='eop-form-blue-text' />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item label='Ističe dana' name='expireDate'>
                <DatePicker locale={locale} format='DD-MM-YYYY' disabled className='eop-form-blue-text' />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={3}>
              <Form.Item label='Datum evidentiranja' name='entryDate'>
                <DatePicker locale={locale} format='DD-MM-YYYY' className='eop-form-blue-text' />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row type='flex' gutter={16}>
            <Col span={8}>
              <Form.Item label='Primedba' name='note'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={8}>
              <Form.Item label='Klazula Anonimnosti' name='anon'>
                <Radio.Group onChange={onRadioChange} value={anon}>
                  <Radio value={true}>Da</Radio>
                  <Radio value={false}>Ne</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={8}>
              <Form.Item label='Poreklo imovine' name='origin'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={8}>
              <Form.Item label={`Razlozi za sumnju u 'pranje novca'`} name='moneyLaunder'>
                <TextArea autoSize={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' gutter={16}>
            <Col span={8}>
              <Form.Item label='Podaci o stvarnim vlasnicima stranke' name='realOwnerData'>
                <TextArea autoSize={true} />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <div className='text-right'>
              <Button type='primary' htmlType='submit'>
                {isNew ? 'Kreiraj' : 'Ispravi'} EOP
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EopForm;
