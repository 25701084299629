import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';
import { Layout, Menu, Select } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Sider } = Layout;
const { Header } = Layout;
const NavMenu = (props) => {
  const { isCollapsed, collapsedNav, handleCollapsedNav } = props;
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser?.data?.token, 'get');
  const [count, setCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (currentuser?.data?.token !== null) {
      fetchData(
        `${SERVER_URL}/data?filter=` +
          encodeURIComponent(
            JSON.stringify({
              isExpiredContract: true,
              $and: [
                { sold: { $ne: true } },
                { rented: { $ne: true } },
                { clientGaveUp: { $ne: true } },
                { ownerSold: { $ne: true } },
              ],
            }),
          ),
        [],
      );
    }
  }, [currentuser?.data?.token, location.pathname]);

  useEffect(() => {
    if (data.data.items) {
      setCount(data.data.items.length);
    } else {
      setCount(0);
    }
  }, [data.data.items]);

  const countLength = count.toString().length;
  const size = Math.max(20 + countLength * 5, 20);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <Header className={`gl_header ${collapsedNav ? 'isActive' : ''}`}>
      <button className='btn-close btn-close-menu' onClick={handleCollapsedNav}>
        <span className='icon'>&#10005;</span>
      </button>

      <Link to='/admin' className='logo-desktop' onClick={handleCollapsedNav}>
        <div className='logo'>
          <img src='/logoCenzar.svg' style={{ maxHeight: '49px', width: '164px' }} alt='Cenzar Nekretnine Subotica' />
        </div>
      </Link>
      <Menu theme='dark' mode='horizontal' defaultSelectedKeys={[window.location.pathname]}>
        {ROUTES.filter((route) => route.showInMenu).map((route, key) => (
          <SubMenu
            className='custom-menu-keys'
            key={route.label.toLowerCase()}
            title={
              <span className='menu-option'>
                <img src={route.icon} />
                {route.label === 'Nekretnine Ugovor' && count !== 0 && (
                  <span className='menu-badge' style={{ width: `${size}px`, height: `${size}px` }}>
                    <p>{count}</p>
                  </span>
                )}
                {route.label}
              </span>
            }
          >
            {route.children
              .filter((item) => item.showInMenu)
              .map((item) => (
                <Menu.Item key={item.path} onClick={handleCollapsedNav}>
                  <Link to={item.path}>{item.label}</Link>
                </Menu.Item>
              ))}
          </SubMenu>
        ))}
      </Menu>
      <div className='sider-profile-controls'>
        <div className='account'>
          <Link
            to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}
            className='sider-profile-controls-profile'
            onClick={handleCollapsedNav}
          >
            <UserOutlined />
          </Link>

          <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
        </div>

        <Select
          virtual={false}
          className='sider-profile-controls sider-profile-controls-language'
          value={currentuser.language && currentuser.language.selected.name}
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={(lang) =>
            currentuser.setLanguage({
              ...currentuser.language,
              selected: currentuser.language.list.find((l) => l.name === lang),
            })
          }
        >
          {currentuser.language &&
            currentuser.language.list.map((lang) => (
              <Select.Option value={lang.name} key={lang.name}>
                {lang.name}
              </Select.Option>
            ))}
        </Select>
      </div>
    </Header>
  );
};
export default NavMenu;
